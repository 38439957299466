import { faAdd } from '@fortawesome/pro-solid-svg-icons';

import PromptsEmptySrc from '@/assets/prompts-empty.svg';
import { RMButton } from '@/components/RMButton/RMButton';
import { RMText } from '@/components/RMText/RMText';

import { Container, Image } from './PromptListEmpty.styles';

export interface PromptListEmptyProps {
  hasTopics: boolean;
  onAddPrompts: () => void;
}

export function PromptListEmpty({ hasTopics, onAddPrompts }: PromptListEmptyProps) {
  return (
    <Container>
      <Image src={PromptsEmptySrc} alt="Prompt ghost icon" />
      <RMText align="center" type="sans" size="s" color="on-surface-tertiary">
        Stories start with {hasTopics ? 'prompts' : 'photos'}.
        <br />
        Add your first {hasTopics ? 'prompts' : 'photos'} to get started.
      </RMText>
      <RMButton background="outlined" leftIcon={faAdd} onClick={onAddPrompts}>
        Add {hasTopics ? 'prompts' : 'photos'}
      </RMButton>
    </Container>
  );
}
